import { useAppSelector } from "@/store/store-hooks";
import { selectCurrentUser } from "@/store/user-selectors";
import {
  blue,
  FaroDialog,
  FaroText,
  neutral,
  useToast,
} from "@faro-lotv/flat-ui";
import {
  Box,
  IconContainerProps,
  Rating,
  styled,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import { ChangeEvent, useEffect, useRef, useState } from "react";

function IconContainer({ value, ...other }: IconContainerProps): JSX.Element {
  return <span {...other}>{value}</span>;
}

/**
 * The code snippet customizes the Material-UI Rating component using the styled function
 * from @mui/material/styles, creating a new component named StyledRating.
 */
const StyledRating = styled(Rating)(() => ({
  "&.feedback-rating": {
    gap: "48px",
    display: "flex",
    margin: "14px auto 0 auto",
  },
  "&.feedback-rating > label": {
    position: "relative",
  },
  "&.feedback-rating > label::after": {
    content: "''",
    position: "absolute",
    width: "100%",
    height: "1px",
    backgroundColor: neutral[200],
    top: "50%",
    transform: "translateY(-50%)",
    left: "100%",
  },
  "&.feedback-rating > label:nth-of-type(n + 5)::after": {
    display: "none",
  },
  "& .MuiRating-iconEmpty": {
    color: neutral[800],
  },
  "& .MuiRating-iconFilled.MuiRating-iconHover, .MuiRating-iconFilled, .MuiRating-iconEmpty":
    {
      minWidth: "50px",
      height: "50px",
      border: `1px solid ${neutral[200]}`,
      borderRadius: "100%",
      justifyContent: "center",
      alignItems: "center",
      transform: "scale(1)",
    },
  "& .MuiRating-iconFilled.MuiRating-iconHover, .MuiRating-iconFilled": {
    color: "white",
    background: blue[500],
    fontSize: "20px",
  },
}));

/** Analysis feedback channel that is owned by CADBIM team */
const ANALYSIS_FEEDBACK_CHANNEL =
  "https://prod-26.westus.logic.azure.com:443/workflows/5d14b104a8ad4c7abafd7c68c74fc41f/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=XjXe2sZvX0XH3yGKDm16gbcTHl8Y7RHDvUcbp6GZTkE";

interface SendFeedbackFormProps {
  closeDialog(): void;
}

/**
 * Contains the send-feedback form about the color map analysis tool.
 *
 * @returns the analysis feedback form dialog
 */
export function AnalysisFeedbackForm({
  closeDialog,
}: SendFeedbackFormProps): JSX.Element {
  const feedbackRef = useRef<HTMLTextAreaElement>(null);

  const { openToast } = useToast();

  /** Whether it's posting the feedback and shows spinner at the submit button */
  const [isUpLoading, setIsUpLoading] = useState<boolean>(false);

  /** Stores the selected rating */
  const [rating, setRating] = useState<number>(0);

  /** Store the text area text */
  const [textArea, setTextArea] = useState<string>("");

  const email = useAppSelector(selectCurrentUser)?.email ?? "";

  /** Cleans up the state and closes the dialog */
  function onClose(): void {
    closeDialog();
    setRating(0);
    setTextArea("");
  }

  /** Submits the feedback for analysis tool*/
  async function onSubmitFeedback(): Promise<void> {
    setIsUpLoading(true);
    const feedbackContent = {
      rating,
      message: textArea,
      email,
    };
    await fetch(ANALYSIS_FEEDBACK_CHANNEL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(feedbackContent),
    });

    openToast({
      title: "Thank you for your feedback!",
      variant: "success",
      message: "We'll use it to make your experience even better.",
    });
    onClose();
    setIsUpLoading(false);
  }

  /**
   * This function handles changes in a textArea.
   *
   * @param {ChangeEvent<HTMLTextAreaElement>} event - The event object containing information about the textarea change.
   * @returns {void}
   */
  function handleChangeTextArea(event: ChangeEvent<HTMLTextAreaElement>): void {
    const { value } = event.target;

    setTextArea(value);
  }

  useEffect(() => {
    if (rating > 0) {
      if (feedbackRef.current) {
        feedbackRef.current.focus();
      }
    }
  }, [rating]);

  return (
    <FaroDialog
      title="How beneficial is the analysis tool for your work?"
      open
      onCancel={onClose}
      onConfirm={onSubmitFeedback}
      showSpinner={isUpLoading}
      isConfirmDisabled={!rating}
      confirmText="Submit"
      sx={{
        position: "absolute",
        right: "0",
        bottom: "0",
        top: "initial",
        left: "initial",
        backdropFilter: "none",
      }}
      hideBackdrop={true}
    >
      <StyledRating
        classes={{ root: "feedback-rating" }}
        name="feedback"
        IconContainerComponent={IconContainer}
        onChange={(_, newValue) => setRating(newValue ?? 0)}
        value={rating}
        highlightSelectedOnly
        data-testid="send-feedback-rating"
      />
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          marginTop: "12px",
          marginRight: "30px",
        }}
      >
        <FaroText variant="bodyM" fontSize="12px" color={neutral[600]}>
          Not helpful
        </FaroText>
        <FaroText variant="bodyM" fontSize="12px" color={neutral[600]}>
          Very helpful
        </FaroText>
      </Stack>
      {rating > 0 && (
        <Box component="div" sx={{ marginTop: "47px" }}>
          <FaroText variant="bodyM" fontSize="14px" color={neutral[800]}>
            Want to tell us more?
          </FaroText>
          <TextField
            minRows={4}
            placeholder="Enter..."
            data-testid="send-feedback-textarea"
            onChange={handleChangeTextArea}
            defaultValue={textArea}
            multiline
            fullWidth
            sx={{
              color: neutral[800],
            }}
          />
        </Box>
      )}
    </FaroDialog>
  );
}
