import { EasterEgg } from "@/components/EasterEgg";
import { useDeepLinkRequiredElement } from "@/components/common/deep-link/deep-link-context";
import { ElementFileUploadContextProvider } from "@/components/common/point-cloud-file-upload-context/element-file-upload-context";
import { ProjectProvider } from "@/components/common/project-provider/project-provider";
import { RenderingSettingsContextProvider } from "@/components/common/rendering-settings-context";
import { SceneEventsContextProvider } from "@/components/common/scene-events-context";
import { UnitOfMeasureContextProvider } from "@/components/common/unit-of-measure-context";
import { AnalysisFeedbackForm } from "@/components/ui/analysis-feedback-form";
import { AppBanner } from "@/components/ui/app-banner";
import { BackgroundTaskNotifier } from "@/components/ui/background-task-notifier";
import { ExclusiveHeaderBar } from "@/components/ui/exclusive-header-bar";
import { LanguageSelector } from "@/components/ui/language-selector";
import { ModalSpinner } from "@/components/ui/modal-spinner";
import { ModeCanvas } from "@/components/ui/mode-canvas";
import { ProgressOverviewMenu } from "@/components/ui/progress-overview/progress-overview-menu";
import { ProjectBreadcrumbs } from "@/components/ui/project-breadcrumbs";
import {
  ProjectOverview,
  ProjectOverviewBase,
} from "@/components/ui/project-overview";
import { QuickHelpButton } from "@/components/ui/quick-help-button";
import { ShareLinkButton } from "@/components/ui/share-link-button";
import { TreeContextProvider } from "@/components/ui/tree/tree-context";
import { ViewerHelpCenterMenu } from "@/components/ui/viewer-help-center-menu";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { useHandleGlobalEscape } from "@/hooks/use-handle-global-escape";
import { useOpenAccountAndSecurity } from "@/hooks/use-open-account-and-security";
import { getMode } from "@/modes";
import { ModeDataProvider } from "@/modes/mode-data-context";
import { EnsureProjectAccess } from "@/permissions/ensure-project-access";
import { runtimeConfig } from "@/runtime-config";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { selectModeName } from "@/store/mode-selectors";
import { selectActiveAnalysis } from "@/store/point-cloud-analysis-tool-selector";
import { useAppSelector } from "@/store/store-hooks";
import { selectActiveTool, selectShowSpinner } from "@/store/ui/ui-selectors";
import { selectCurrentUser } from "@/store/user-selectors";
import { appId } from "@/utils/appid";
import { APP_VERSION } from "@/utils/env-constants";
import { ProgressApiTracker } from "@/utils/progress-api-tracker";
import {
  blue,
  FaroButton,
  FaroText,
  FeedbackIcon,
  HeaderBar,
  LoadingScreen,
  useBreakpointMdUp,
} from "@faro-lotv/flat-ui";
import { useAnalyticsInitialization } from "@faro-lotv/foreign-observers";
import { useAuthContext } from "@faro-lotv/gate-keepers";
import {
  selectDashboardUrl,
  selectProjectName,
} from "@faro-lotv/project-source";
import { ApiClientContextProvider } from "@faro-lotv/service-wires";
import { Stack } from "@mui/material";
import { omit } from "lodash";
import { useCallback, useState } from "react";
import { useParams } from "react-router";

/**
 * @returns Loads the project with the provided UI from the API, and then renders the main UI
 */
export function ProjectPage(): JSX.Element | null {
  const { projectId } = useParams();
  const currentUser = useAppSelector(selectCurrentUser);

  if (!projectId) {
    throw Error("No project ID provided");
  }
  const [isReady, setIsReady] = useState(false);

  const deepLinkRequiredElement = useDeepLinkRequiredElement();

  useAnalyticsInitialization(
    runtimeConfig.analytics.amplitudeApiKey,
    currentUser?.email,
  );

  const showLoadingScreen = !isReady;

  return (
    <ApiClientContextProvider
      projectId={projectId}
      userId={currentUser?.id}
      clientId={appId()}
      apiBaseUrls={runtimeConfig.backendEndpoints}
    >
      <EnsureProjectAccess projectId={projectId}>
        <ProjectProvider
          requiredItem={deepLinkRequiredElement}
          projectId={projectId}
          onReady={setIsReady}
        >
          <ModeDataProvider>
            <ElementFileUploadContextProvider>
              <SceneEventsContextProvider>
                <RenderingSettingsContextProvider>
                  <UnitOfMeasureContextProvider>
                    {!showLoadingScreen && <MainUi />}
                  </UnitOfMeasureContextProvider>
                </RenderingSettingsContextProvider>
              </SceneEventsContextProvider>
              <ProgressApiTracker />
            </ElementFileUploadContextProvider>
          </ModeDataProvider>
        </ProjectProvider>

        {showLoadingScreen && (
          <Stack
            direction="row"
            sx={{ width: "100%", height: "100%" }}
            justifyContent="center"
          >
            <LoadingScreen />
          </Stack>
        )}
      </EnsureProjectAccess>
    </ApiClientContextProvider>
  );
}

/**
 * @returns The main UI layout for the app
 */
function MainUi(): JSX.Element {
  const modeName = useAppSelector(selectModeName);
  const mode = getMode(modeName);
  const showSpinner = useAppSelector(selectShowSpinner);

  const currentUser = useAppSelector(selectCurrentUser);
  const projectName = useAppSelector(selectProjectName);

  const { requestLogin, logout } = useAuthContext();

  const openAccountAndSecurity = useOpenAccountAndSecurity();

  const isMdUp = useBreakpointMdUp();

  useDocumentTitle(projectName);

  const canShareCurrentScene = !mode.exclusive && mode.name !== "start";

  // analysis user feedback form related state and callback
  const [isFeedbackFormOpen, setIsFeedbackFormOpen] = useState<boolean>(false);
  const closeFeedbackForm = useCallback(() => setIsFeedbackFormOpen(false), []);

  // Only use the backend defined dashboard url so guest users of unlisted project
  // will not get redirected to the old HB dashboard by mistake
  const dashboardUrl = useAppSelector(selectDashboardUrl);
  const links = {
    ...omit(runtimeConfig.externalLinks, "dashboardUrl"),
    dashboardUrl,
  };

  useHandleGlobalEscape();

  const showLanguageSelector = useAppSelector(
    selectHasFeature(Features.Localize),
  );

  const activeTool = useAppSelector(selectActiveTool);
  const activeAnalysis = useAppSelector(selectActiveAnalysis);

  return (
    <TreeContextProvider>
      <Stack sx={{ height: "100%", width: "100%", overflow: "hidden" }}>
        {mode.exclusive && !mode.exclusive.useDefaultHeader ? (
          <ExclusiveHeaderBar
            {...mode.exclusive}
            hasQuickHelp={!!mode.ModeQuickHelpDrawer}
          />
        ) : (
          <HeaderBar
            userDisplayInfo={currentUser}
            links={links}
            appVersion={APP_VERSION}
            onLogInClick={requestLogin}
            onLogOutClick={logout}
            onAccountAndSecurityClick={openAccountAndSecurity}
            content={<ProjectBreadcrumbs />}
            drawerContent={
              !mode.exclusive && !isMdUp ? <ProjectOverviewBase /> : undefined
            }
            userMenuContent={showLanguageSelector ? <LanguageSelector /> : null}
          >
            {(activeTool === "analysis" || activeAnalysis?.isVisible) && (
              <FaroButton
                variant="ghost"
                icon={<FeedbackIcon />}
                onClick={() => setIsFeedbackFormOpen(true)}
              >
                <FaroText variant="bodyM" color={blue[600]}>
                  Share your thoughts on the analysis tool
                </FaroText>
              </FaroButton>
            )}
            {canShareCurrentScene && <ShareLinkButton />}
            <ViewerHelpCenterMenu />
            {mode.ModeQuickHelpDrawer && <QuickHelpButton />}
            <ProgressOverviewMenu />
          </HeaderBar>
        )}

        {!mode.exclusive && <AppBanner />}

        <Stack flexGrow={1} flexDirection="row" overflow="auto">
          {!mode.exclusive && isMdUp && <ProjectOverview />}
          <Stack flexGrow={1} overflow="auto">
            <ModeCanvas modeName={modeName} />
          </Stack>
          {mode.ModeDrawer && <mode.ModeDrawer />}
          {mode.ModeQuickHelpDrawer && <mode.ModeQuickHelpDrawer />}
          <BackgroundTaskNotifier />
        </Stack>
      </Stack>
      <ModalSpinner
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showSpinner}
      />
      <EasterEgg />
      {isFeedbackFormOpen && (
        <AnalysisFeedbackForm closeDialog={closeFeedbackForm} />
      )}
    </TreeContextProvider>
  );
}
